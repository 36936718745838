import React from 'react'
import Link from '../LinkChecker'
import TabsItemDefault from '../TabsItemDefault'

const TabsItem = ({ value, isWidget, ...props }) => {
  const tabOptions = {}
  if (!isWidget) {
    tabOptions.component = Link
    tabOptions.to = value
  }
  return <TabsItemDefault tabOptions={tabOptions} isWidget={isWidget} value={value} {...props} />
}

export default TabsItem
