import React from 'react'
import styled, { css } from 'styled-components'
import MaterialTab from '@mui/material/Tab'

const TabsItemDefault = styled(MaterialTab).attrs(({ icon, _color, indicatorColor, nowrap, tabOptions, ...props }) => {
  return {
    scrollable: 'scrollable',
    disableTouchRipple: true,
    nowrap: nowrap,
    _color: _color ? _color : props.theme.color.textSecondary,
    classes: { wrapper: 'label', root: 'root', selected: 'selected' },
    ...tabOptions,
  }
})`
  &&.root {
    border-bottom: 2px solid ${(props) => props.theme.color.textTertiary};
    text-transform: none;
    font-family: ${(props) => props.theme.font.primary};
    font-size: ${(props) => props.theme.fontSize.base};
    color: ${(props) => props._color};
    flex: 1;
    overflow: unset;
    padding: 0 10px;
    min-width: unset;
    ${(props) =>
      props.nowrap &&
      css`
        white-space: nowrap;
      `}

    .label {
      font-size: ${(props) => props.theme.fontSize.base};
      text-transform: none;
      flex-direction: row;

      && > i {
        margin-right: 5px;
      }

      && > i:first-child {
        margin-bottom: 0px;
      }
    }
  }

  &&.selected {
    color: ${(props) => (props.indicatorColor ? props.indicatorColor : props.theme.color.stol)};
    fill: ${(props) => (props.indicatorColor ? props.indicatorColor : props.theme.color.stol)};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    &&.root {
      font-size: ${(props) => props.theme.tablet.fontSize.base};

      .label {
        font-size: ${(props) => props.theme.tablet.fontSize.base};
      }
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    &&.root {
      font-size: ${(props) => props.theme.mobile.fontSize.base};

      .label {
        font-size: ${(props) => props.theme.mobile.fontSize.base};
      }
    }
  }
`

export default TabsItemDefault
